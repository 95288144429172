.dropdown-nav {
  @include screen-box-shadow();

  visibility: hidden;
  box-sizing: initial; // override bootstrap defaults. we do not use bootstrap outside of account management, so we must match its box sizing here.
  transition: opacity .25s ease-in-out;
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 32px;
  right: -35px;
  width: 250px;
  padding: 0 15px;
  background: #fff;
  border: 1px solid #ddd;

  &--login {
    top: 32px;
    width: auto;
    white-space: nowrap;
    padding: 0 15px;
  }

  &--account-management {
    top: 40px;
    left: 244px;
  }

  &__line {
    border-top: 1px solid #ccc;
    margin: 10px 0;
  }

  &__arrow-up {
    position: absolute;
    top: -15px;
    right: 25px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #DDD;

    &--overlay {
      position: absolute;
      top: -14px;
      right: 25px;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid #FFF;
    }
  }

  &__name {
    margin: 15px 0;
    letter-spacing: -.5px;
    font-size: 24px;
  }

  &__list {
    padding: 0 10px;
    font-size: 15px;
  }

  &__list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0;
  }

  &__link {
    color: #555;

    svg {
      width: 19px;
      height: 19px;
      vertical-align: middle;
    }

    .icon {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
    }

      svg path {
      fill: #555;
    }

    span {
      margin-left: 5px;
    }

    &:focus {
      text-decoration: none;
    }

    &:hover {
      color: #777;
      text-decoration: none;

      svg path {
        fill: #555;
      }
    }
  }

  &.dropdown-content--visible {
    transition: opacity .25s ease-in-out;
    visibility: visible;
    opacity: 1;
    z-index: 9999;
  }
}
