a.skip-to-mp {
  outline-offset: .25em;
  position: fixed;
  width: calc(100% - 100px);
  height: auto;
  bottom: 100%;
  right: 100%;
  color: #000;
  font-family: var(--default-font);
  font-size: 12px;
  text-align: center;

  &:focus {
    top: 0;
    left: 50px;
  }

  @media screen and (min-width: 768px){
    width: 100%;

    &:focus {
      top: 0;
      left: 0;
    }
  }

  abbr {
    display: inline;
    border: none;
  }
}
