.dropdown-nav {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  box-sizing: initial;
  transition: opacity 0.25s ease-in-out;
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 32px;
  right: -35px;
  width: 250px;
  padding: 0 15px;
  background: #fff;
  border: 1px solid #ddd;
}
.dropdown-nav--login {
  top: 32px;
  width: auto;
  white-space: nowrap;
  padding: 0 15px;
}
.dropdown-nav--account-management {
  top: 40px;
  left: 244px;
}
.dropdown-nav__line {
  border-top: 1px solid #ccc;
  margin: 10px 0;
}
.dropdown-nav__arrow-up {
  position: absolute;
  top: -15px;
  right: 25px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #DDD;
}
.dropdown-nav__arrow-up--overlay {
  position: absolute;
  top: -14px;
  right: 25px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #FFF;
}
.dropdown-nav__name {
  margin: 15px 0;
  letter-spacing: -0.5px;
  font-size: 24px;
}
.dropdown-nav__list {
  padding: 0 10px;
  font-size: 15px;
}
.dropdown-nav__list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
}
.dropdown-nav__link {
  color: #555;
}
.dropdown-nav__link svg {
  width: 19px;
  height: 19px;
  vertical-align: middle;
}
.dropdown-nav__link .icon {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}
.dropdown-nav__link svg path {
  fill: #555;
}
.dropdown-nav__link span {
  margin-left: 5px;
}
.dropdown-nav__link:focus {
  text-decoration: none;
}
.dropdown-nav__link:hover {
  color: #777;
  text-decoration: none;
}
.dropdown-nav__link:hover svg path {
  fill: #555;
}
.dropdown-nav.dropdown-content--visible {
  transition: opacity 0.25s ease-in-out;
  visibility: visible;
  opacity: 1;
  z-index: 9999;
}

a.skip-to-mp {
  outline-offset: 0.25em;
  position: fixed;
  width: calc(100% - 100px);
  height: auto;
  bottom: 100%;
  right: 100%;
  color: #000;
  font-family: var(--default-font);
  font-size: 12px;
  text-align: center;
}
a.skip-to-mp:focus {
  top: 0;
  left: 50px;
}
@media screen and (min-width: 768px) {
  a.skip-to-mp {
    width: 100%;
  }
  a.skip-to-mp:focus {
    top: 0;
    left: 0;
  }
}
a.skip-to-mp abbr {
  display: inline;
  border: none;
}